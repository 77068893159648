@import "../../scss/iconfont.scss";

.header-bar{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  background-image: url(https://static.hhocool.com/images/d526c012-75c4-4fc1-9a00-28348e08d78c_750x176.png);
  background-size: 100% 100%;
  .header-back-btn{
    color: #fff;
    .iconfont{
      font-size: 24px;
    }
  }
  .header-title{
    flex: 1;
    color: #fff;
    text-align: left;
    font-size: 16px;
    padding-left: 12px;
  }

  .header-more-btn{
    // width: 36px;
    margin-left: 12px;
  }
}
