.product-item-wrapper {
  width: calc(50vw - 18px);
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
}
.product-main-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 8px 8px 0 0;
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 8px 8px 0 0;
  }
}

.product-main-video{
  width: 100%;
  height: 0;
  padding-bottom: 125%;
  position: relative;
  border-radius: 8px 8px 0 0;
  .video{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 8px 8px 0 0;
  }
}
.product-title {
  box-sizing: border-box;
  padding: 0 10px;
  margin: 10px 0;
  color: #1a1a1a;
  font-size: 13px;
  line-height: 18px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 56px;
}

.product-price {
  padding: 0 12px 12px;
  font-family: "DIN Alternate";
  color: #f56646;
  font-weight: 700;
  .number {
    font-size: 20px;
    line-height: 23px;
  }
  .unit {
    font-size: 12px;
  }
}
