.index-container {
  overflow-x: hidden;
  background-color: #f4f4f4;
  padding-top: 82px;
  background-image: linear-gradient(90.34deg, rgb(209, 255, 232) 0%, rgb(255, 229, 209) 100%);
}
.image-element-class {
  width: 40vw;
}

.recommend {
  background-color: #f4f4f4;
  border-radius: 8px 8px 0 0;
  &-title {
    display: flex;
    align-items: center;
    padding: 24px 12px 16px;
    &-text {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.customize-banner {
  box-sizing: border-box;
  width: 100%;
  background-image: linear-gradient(90.34deg, #d1ffe8 0%, #ffe5d1 100%);
  text-align: center;
  padding-bottom: 8px;
  position: relative;
  .to-custom {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 20px;
    top: 36px;
  }
  .banner-img {
    width: 100%;
  }
  .swiper-indicator {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    display: inline-block;
    position: absolute;
    left: 10vw;
    bottom: 18px;
    background-image: linear-gradient(#50f25b, #41faef);
    transition: left .2s;
  }
}

.my-gallery-class {
  box-sizing: border-box;
}

.swiper-wrapper {
  width:42vw;
  height: 49vw;
  position: absolute;
  left: 2.7vw;
  top: 2vw;
  .swiper-img {
    width: 100%;
    height: 100%;
  }
}
.swiper-slide{
  position: absolute;
  width: 100%;
  height: 56px;
  bottom: 19px;
  padding: 0 12px;
  display: flex;
  .slide-item{
    flex: 1;
    height: 100%;
  }
}
